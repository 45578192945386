import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../../layout'

import BackgroundContainer from '../../../components/image/background-container'
import TitleContainer from '../../../components/common/title-container'
import Title from '../../../components/common/title'
import Link from '../../../components/common/link'
import Subtitle from '../../../components/common/subtitle'
import PageContainer from '../../../components/common/page-container'

export default (props) => (
  <Layout title="Gatsby.js">
    <BackgroundContainer header={props.data.header}>
      <TitleContainer>
        <Title>Gatsby.js</Title>
      </TitleContainer>
    </BackgroundContainer>

    <PageContainer>
      <p>
        Gatsby is a <Link href="/services/front-end-development/react">React</Link>-based framework which helps developers build blazing fast websites.
      </p>

      <p>
        One of the features that makes Gatsby so flexible is the ability to use plugins to populate websites with data. There are many types of plugins supported including:

        <ul>
          <li>Content manangement systems</li>
          <li>E-commerce systems</li>
          <li>Subscription billing systems</li>
        </ul>
      </p>

      <p>packagedby have extensive experience in building websites and custom plugins with Gatsby.</p>
    </PageContainer>
  </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "gatsby.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`